.Dashboard {
  height: 100%;
  margin: 0 auto;
  background-color: #a8dadc;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    height: 100vh;
    width: calc(100% - 72px);
    flex-wrap: wrap;
    display: flex;
  }

  &__item {
    display: flex;
    width: 100%;
    justify-content: center;
    max-height: 280px;
  }
  .swiper {
    width: 100%;
  }

  &__line{
    min-width: 50%;
    display: flex;
    justify-content: center;
  }
}
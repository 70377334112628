.login-page {

  height: 100vh;
  margin: 0 auto;
  background-color: rgb(239, 239, 239);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;


  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .reg-form__btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
  }

  .btn {
    cursor: pointer;
    padding: 5px 10px;
    background: #1d3557;
    color: white;
    border: 1px solid black;

    &:hover {
      transition: all 0.3s;
      color: #457b9d;
    }
  }
}
.menu {

  &__wrapper {
    cursor: pointer;
    width: 32px;
    padding: 20px;
    background: #1d3557;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 3;
    height: 100%;
    position: fixed;
    transition: all .3s;
  }

  &__hover {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    backdrop-filter: brightness(60%) blur(5px);
    display: none;
  }

  &__item {
    font-size: 24px;
    list-style-type: none;
    padding-bottom: 15px;
  }

  &__item-link {
    text-decoration: none;
    color: #f1faee;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      transition: all .3s;
      color: #457b9d;
    }
  }

  &__item-text {
    display: none;
  }

  &__link-wrapper {
    &:first-child {
      margin-bottom: 60px;
    }

    padding: 0;
  }

  &__img {
    fill: #f1faee;
    height: 32px;
    width: 32px;
    transition: all .3s;
    position: relative;

    &:hover {
      fill: #f1faee7a;
    }
  }


  .logo {
    position: absolute;
    flex-direction: column;
    align-items: center;
    bottom: 20px;
    left: 50%;
    opacity: 0;
    transition: opacity 04s;
    display: flex;
    transform: scaleY(0) translate(-50%, -50%);

    &__img {
      width: 60px;
      height: 60px;
    }

    &__title {
      color: #F1FAEE;
      font-family: 'Times New Roman', Times, serif;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 5px;
      text-align: center;
      min-width: 297px;
    }

  }

  &--active {
    .menu__wrapper {
      width: 320px;
    }

    .menu__img {
      display: none;
    }

    .menu__item-link {
      display: flex;
    }

    .logo {
      opacity: 1;
      transform: scaleY(1) translate(-50%, -50%);
    }

    .menu__item-text {
      display: block;
    }

    .menu__img {
      display: none;
    }

    .menu__hover {
      display: block;
    }
  }

}
.input {
  margin-top: 6px;
  border: 1px solid rgb(0, 0, 0);
  margin-bottom: 8px;
  padding: 4px;

  &__label {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  &:focus {
    background-color: #1d3557;
    border: 1px solid rgb(0, 0, 0);
    outline: none;
    color: rgb(255, 255, 255);
  }

  &__error--text {
    display: none;
    position: absolute;
    left: 0;
    bottom: -7px;
    font-size: 12px;
  }

  &__error {
    
    color: rgb(246, 0, 0);
    .input__error--text {
      display: block;
    }

    .input {
      border: 1px solid rgb(246, 0, 0);
    }
  }
}